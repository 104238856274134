import request from '@/utils/request'

// 用户信息 分页
export function userPage(query) {
  return request({
    url: '/wisdom/open-api-user/page',
    method: 'get',
    params: query
  })
}

// 用户信息 详情
export function userInfo(query) {
  return request({
    url: '/wisdom/open-api-user/detail',
    method: 'get',
    params: query
  })
}

// 用户信息 新增
export function userAdd(data) {
  return request({
    url: '/wisdom/open-api-user/add',
    method: 'post',
    data
  })
}

// 用户信息 编辑
export function userEdit(data) {
  return request({
    url: '/wisdom/open-api-user/edit',
    method: 'post',
    data
  })
}

// 用户信息 重置密码
export function userReset(data) {
  return request({
    url: '/wisdom/open-api-user/password',
    method: 'post',
    data
  })
}

// 用户信息 启用、禁用
export function userStatus(data) {
  return request({
    url: '/wisdom/open-api-user/open',
    method: 'post',
    data
  })
}

// 用户信息 删除
export function userDel(data) {
  return request({
    url: '/wisdom/open-api-user/delete',
    method: 'post',
    data
  })
}

// 查询某个用户的设备分页
export function userDevicePage(query) {
  return request({
    url: '/wisdom/open-api-user-dev/page',
    method: 'get',
    params: query
  })
}

// 新增设备用户关系
export function userDeviceAdd(data) {
  return request({
    url: '/wisdom/open-api-user-dev/add',
    method: 'post',
    data
  })
}

// 解绑设备用户关系
export function userDeviceDel(data) {
  return request({
    url: '/wisdom/open-api-user-dev/delete',
    method: 'post',
    data
  })
}
